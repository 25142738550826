@import '../../../../Styles/Colors.scss';
@import '../../../../Styles/TextFont.scss';

.button-top-content{
    margin-bottom: 20px;
}


.button-top-content__idiom-select{

}


.button-top-content__add-text{

}


.add-text__item{
    display: inline;
    margin-right: 20px;
}


.fillable-field-create-content__fillable-field{

}


.fillable-field-create-content__static-text{
    border-color: map-get($theme-colors, 6F86AA);
    background-color: map-get($theme-colors, B6C6DE);
}


.content-item{
    margin-bottom: 10px;
}


.button-bottom-content{

    display: flex;
}

.button-bottom-content__save{
    margin-left: auto;
    display: flex;
}