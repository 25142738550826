
.no-links-multimedia{
    margin-top: 23px;
}

.no-links-multimedia__at-title{
    margin-bottom: 13px;
}

.no-links-multimedia__items-container{
    margin-bottom: 10px;
}
.no-links-multimedia__items{
    display: flex;
    
    margin-bottom: 9px;
    vertical-align: middle;
}

.no-links-multimedia__item{
    vertical-align: middle;
    margin: auto  0;
}

.no-links-multimedia__item__label{
    width: 378pt;
    
}

.no-links-multimedia__items__img{
    max-width: 67px;
    max-height: 45px;
    margin-right: 19px;
}

.no-links-multimedia__item__select{
    margin-right: 29px;
}