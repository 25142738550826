.background-images{
    position: relative;
    margin:auto;
    @media(min-width:1200px){
        //width: 80%;
    }
    &__background{
        width: 100%;
        display: block;
    }
    &__loader{
        position: absolute;
        top: 21.5%;
        left: 23.1%;
        width: 54.4%;
        height: 49.5%;
        &__resize{
            position:relative;
            width:100%;
            height:100%;
            & > img {
                max-width: 100%;
                max-height: 100%;
                width: 100%;
                height: 100%;
                object-fit: contain;
                //margin: auto;
                //display: block;
                //position: absolute;
                //top: 50%;
                //left: 50%;
                //transform: translate(-50%,-50%);
            }
            & > .disabled{
                display: none !important;
            }
        }
        
    }




    & > button {
        background: none;
        border: none;
        margin: 0;
        padding: 0;
        position: absolute;
        bottom: 20.5%;
        left: 22%;
        width: 4.5%;
        &:last-child{
            left: initial;
            right: 21.5%;
            transform: scaleX(-1);
        }
        & > img {
            width: 100%;
        }
    }
}