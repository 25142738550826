.exercises-correct-controller{

    position: fixed; 
    z-index: 100; 
    padding-top: 40px; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100vh; 
    overflow: auto;
    background-color: rgba(0,0,0,0.4); 
    display: flex;
    align-items: center;

    &__content{

        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        width: fit-content;
        border-radius: 20px;

        &__btn{
            background-color: transparent;
        }
    }
}