.org-links-panel{
    /*display: grid;
    grid-template-columns: 67px 60px 60px;
    grid-column-gap: 62px;*/
   
    
}

.org-links-panel__item{
    padding-top:9px;
    display: inline-block;
    vertical-align: middle;
}

.org-links-panel__item--margin-left{
    margin-left: 67px;
}



