
.file-controller{
    width: 90%;
    margin:auto;
    margin-top: 54px;
}

.button-new-file{
    float:right;
    margin-bottom:24px;
}

.file-controller__popup{
    width: 50%;
    margin:auto;
    margin-top: 54px;
    
}