@import './../../../Styles/Colors.scss';
@import './../../../Styles/GeneralCssClasses.scss';

.painter{
  
  &-canvas{
    z-index: 9998;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center center;
    
  }
  &-button{
      z-index: 10000;
      background-color: map-get($theme-colors, PANEL_BACKGROUND);
      border-radius: 50%;
      padding: 0;
      position:absolute;
      top: 4%;
      margin-left: 12px;
      margin-top: 2px;
      width: 50px;
      height: 50px;
      transform: scale(0.8);
      & img{
        width: 50px;
        height: 50px;
      }
  }

  &__button-open-close{
    background-color: map-get($theme-colors, PANEL_BACKGROUND);
    border-radius: 50%;
    padding: 0;
    position:absolute;
    left: 88%;
    & img{
      width: 25px;
      height: 25px;
    }
  }

  &__toolbar{
    
    top: 10%;
    left: 0;
    width: 240px;
    font-weight: bold;
    font-family: 'Ubuntu';
    font-size: 13px;
    background-color: #EADCC8;
    padding: 0.56em;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: absolute;
    z-index: 9999;
    border: 0.31em solid white;
    box-shadow: 3px 3px 30px -10px #2E4467;
    border-radius: 0.6em;
    max-height: 80vh;
    overflow: hidden;

    &-close{

      & img{
        width: 62px;
        height: 62px;
      }
    }

    &-button{
      background: none;
      &--no-active{
        opacity: 0.7;
      }
    }

    &-section{
      
      border-bottom: 0.19em solid white;
      padding-bottom: 0.5em;
      margin-bottom: 0.5em;
    }

    &-title{
      display: flex;
      align-items: center;
      & label{
        margin-left: 0.5em;
      }
      &--vertical{
        flex-direction: column;
      }
    }
    
    &-title-icon{
      width: 2.875em;
      height: 2.875em;
      border-radius: 1.93em;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      & img{
        width: 25px;
      }
      &--paint{
        background-color: map-get($theme-colors, BUTTON_1);
        & img{
          width: 2.25em;
        }
      }
      &--eraser{
        background-color: map-get($theme-colors, BUTTON_2);
        & img{
          width: 2.7em;
        }
      }
    }
    &-colors{
      display: flex;
      justify-content: space-between;
      margin-top: 1em;
      & span{
        width: 2em;
        height: 2em;
        border-radius: 0.31em;
        cursor: pointer;
      }
      @for $i from 1 through 8{
        &-#{$i}{
          background-color: map-get($theme-colors, PAINT_PALET_#{$i});
        }
      }
    }
    &-tools{
      text-align: center;
      & label{
        margin:auto;
        padding-top:0.2em;
        font-weight: bold;
      }
    }
    &-range{
      margin: auto;
      margin-top:0.5em;
      width: 50%;
      display:block;
    }
    &-eraser-all{
      background-color: map-get($theme-colors, BUTTON_2);
      font-size: 1em;
      color:white;
      font-weight: bold;
      text-align: center;
      border-radius: 0.6em;
      margin:auto;
      display:block;
      width: 90%;
      min-height: 2.18em;
    }
    &__canvas{
        width: 100%;
        height: 100%;
        z-index: 9998;

    }
    &-preview {
      width: 90px;
      height: 45px;
      border: 2px solid var(--color-text);
      position: relative;
      margin:auto
    }
    
    &-preview__brush {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      border-radius: 100%;
    }
    & > div:first-of-type {
        flex-grow: 1;
    }
    
  }
  
}
