.test-select-viewer{
    width: 100%;
    margin:auto;
    position:relative;
    @media(min-width:1200px){
        width: 80%;
    }
    @media(min-width:1200px){
        width: 70%;
    }
    &__background{
        
        display: block;
        width: 100%;
    }
    &__exercise{
        position: absolute;
        top: 0;
        left: 20%;
        width: 64.3%;
        margin: 18% auto 0;
        max-height: 81%;
        overflow-y: auto;
        background-color:#F1F1F1 ;
        border-radius: 10px;
    }
    &__section{
        background-color: rgb(255,255,255);
        padding: 1rem;
        border-radius: 10px;
        margin: 20px 10px 20px 10px;
        box-shadow: 2px 2px 10px #7f7f86;

        display: block;
    }

    

    &__answers{
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom:5px solid #F1F1F1;
    }
    &__answers:last-child{
        border:none;
    }
}

