@import '../../../Styles/Colors.scss';
@import '../../../Styles/TextFont.scss';

 .at-select{

   // background-color: map-get($theme-colors, FFFFFF);
    border: none;
    font-size: 14px;
    font-family:map-get($text-font, OpenSans);

    display:flex;
   
    
    
 }
.at-select__span{
   margin:auto auto auto 0;
   
 }

.at-select__select{
   
  
   
 }

