




@keyframes shake {
    from    { transform: rotate(0deg); }
    4%      { transform: rotate(5deg); }
    12.5%   { transform: rotate(-5deg); }
    21%     { transform: rotate(5deg); }
    29%     { transform: rotate(-5deg); }
    37.5%   { transform: rotate(5deg); }
    46%     { transform: rotate(-5deg); }
    50%,to  { transform: rotate(0deg); }
}

@keyframes doubleScaling {
    0% {transform: scale(1);}
    100%,to {transform: scale(2);}
}

@keyframes appears {
    0% {opacity: 0;}
    100%,to {opacity: 1;}
}

@keyframes disappear {
    0% {opacity: 1;}
    100%,to {opacity: 0;}
}

@keyframes rotation {
    0% { transform: rotate(0deg); }
    100%,to { transform: rotate(360deg); }
       
}
