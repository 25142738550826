@import '../../../Styles/Colors.scss';
.at-popup{
    &__modal{
        position: fixed; 
        z-index: 100; 
        padding-top: 40px; 
        left: 0;
        top: 0;
        width: 100%; 
        height: 100vh; 
        overflow: auto;
        background-color: rgba(0,0,0,0.4); 

    }
    &__trigger{
    
        height: 100%;
        width: 100%;
    }
    &__modal-content{
   
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        width: 60%;
        border-radius: 20px;
        position: relative;
        &--transparent{
            background-color: transparent;
            padding: 0;
            border-radius: none;
        }
        &--content-fit{
            width: fit-content;
        }
    }
    &__occupyAll{
        height: 100%;
        width: 100%;
    }
    &__minimize{
        position: absolute;
        bottom: 0; 
        right: 0;
        max-height: 200px;
        max-width: 200px;
        & > &-button{
            top: 0.5rem;
            right: 0.5rem;
        }
    }
    &__minimize-button{
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 0.7rem;
        height: 0.7rem;
        border: solid white;
        border-radius: 2px;
        cursor: pointer;
        &--shadow{
            box-shadow: 4px -3px 0px 0px white;
        }
    }
}

    