@import '../../../Styles/Colors.scss';
@import '../../../Styles/TextFont.scss';

.at-title{

    font-family:map-get($text-font, OpenSans);
    font-size: 14px;
    font-weight: bold;
   
    margin:auto 0;
    
}

