.test-select-create__question{
    display: flex;
}

.question__question{
    display: flex;
    width: 700px;
}

.question__correct{
    margin-left: 30px;
    display: flex;
}

.question__text{
    width: 700px;
}

.content-answers{
    margin-top: 30px;
    display: flex;
}


.content-answers__text{
    //display: flex;
    width: 700px;
}


.content-answers__value{
    display: flex;
    margin-left: 30px;
}


.test-select-create__buttons{
    display: flex;
    margin-top: 30px;
}

.buttons__add-answer{
    display: flex;
}

.buttons__save{
    margin-left: auto;
    display: flex;
}


