@import '../../Styles/Colors.scss';

.new-file{
    
    
    //
    
}

.new-file__item{
    
    margin-bottom: 20px;
}

.inputs-name{

    margin-bottom: 14px;
}

.inputs-name__checkbox input:first-child{

    margin-right: 20px;

}

.resolution, .search-selector, .wallpaper, .miniature{
    display: grid;
    grid-template-columns: auto auto;
}

.wallpaper__item, .resolution__item{
    margin-left: 20px;
}

.button-create-file{
    
    margin-left: 85%;
   
}

.wallpaper__item > div {
    margin-top: 10px;
}

.resolution__item > div{
    margin-top: 10px;
}

.resolution__items-resolution{
    margin-left: 20px;

    display:grid;
    grid-template-columns: auto auto;
}

.resolution__items-resolution > div{
    margin-top: 10px;
}
