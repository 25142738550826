@import './../../../Styles/Colors.scss';
@import '../../../Styles/TextFont.scss';
.mol-text-and-input-text{
    color:map-get($theme-colors, 2E4467);
    font-family:map-get($text-font, OpenSans);
    display:flex;
    align-items: center;
}

.mol-text-and-input-text__span{
    
    margin:auto auto auto 0;
   
}

.mol-text-and-input-text__span--checkbox{

    margin-left: 10px;

}