@import '../../../Styles/Colors.scss';
@import '../../../Styles/TextFont.scss';

.text-area{
    border-width :1px;
    border-radius: 3px 3px 3px 3px;
    border-color: map-get($theme-colors, B6C6DE);
    font-family:map-get($text-font, OpenSans);
    border-width: 1px;
}
