.insert-animation-into-element{
    margin:20px;
}


.insert-animation-into-element__events{
    margin-top: 5px;
    margin-left: 20px;
    margin-bottom: 10px;

}


.insert-animation-into-element__events-items{
    margin-top: 5px;
    

}

.insert-animation-into-element__button-add{
    margin-left: 85%;
}