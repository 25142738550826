@import './../../../../Styles/GeneralCssClasses.scss';

.float-menu-basic-staff__draggable-edit{
    position: absolute;
    top:100px;
    left: 500px;
    z-index: 8000;
}
.float-menu-basic-staff{
    
    background-color: #f19500;
    border:5px solid white;
    border-radius: 20px;
    padding: 25px;
    box-shadow: 3px 3px 30px -10px #2E4467;
    transform: scale(0.7);
    &__title{
        padding: 10px;
        border-radius: 15px;
        background-color: #d40b52;
        width: 201px;
        margin: auto;
        margin-bottom: -20px;
        position: relative;
        top: -36px;
    }
    &__background-white{
        background-color: #fff;
        padding: 15px;
        border:5px solid #d40b52;
    }
}

.float-menu-basic-staff__row{
    display: flex;
    margin-bottom: 25px;
    &__silences{
        margin-right: 7px;
    }
    &__alterations{
        margin-left: 7px;
    }
    &__signs-of-prolongation{

    }
    &__compasses{
        margin-left: 40px;
    }
    &__repeating-signs{
        margin-left: 40px;
    }
    &__clefs{
        margin-left: 40px;
    }
}
.float-menu-basic-staff__row:last-child{
    margin-bottom: 0px;
}

.float-menu-basic-staff__button{
    background: none;
    border-top:3px solid #d40b52;
    border-bottom:3px solid #d40b52;
    border-left: 3px solid #d40b52;
    padding: 0px;
    
}

.float-menu-basic-staff__button img{
    //object-fit: cover;
}

.button-cover img{
    object-fit: cover;
}

.button-contain img{
    object-fit: contain;
}

.float-menu-basic-staff__button:first-child{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.float-menu-basic-staff__button:last-child{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: 3px solid #d40b52;
}

.frist-row{
    
}

.undo-button{
    display: flex;
    color:white;
    background-color: #d40b52;
    padding: 10px;
    border-radius: 15px;
    margin: auto;
}

.img-contain{
    object-fit: contain;
}
.img-cover{
    object-fit: cover;
}