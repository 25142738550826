.div-audio{
    
   } 

.content-floating-audio{
    width: 100%;
    height: 100%;
    position:absolute;
}

.audio{
    display: block;

    margin-right: auto;
    margin-left: auto;
}

.div-floating-audio{
    position: absolute;
    top:100px;
    left: 100px;
    z-index: 1000;
}

.div-floating-audio__hide{
    visibility: hidden;
}
