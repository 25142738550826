
.no-links-multimedia{
    margin-top: 23px;
}

.no-links-multimedia__at-title{
    margin-bottom: 13px;
}

.no-links-multimedia__items-container{
    margin-bottom: 10px;
}
.no-links-multimedia__items{
    display: flex;
    
    margin-bottom: 9px;
    vertical-align: middle;
}

.no-links-multimedia__item{
    vertical-align: middle;
    margin: auto  0;
}

.no-links-multimedia__item__label{
    width: 378pt;
    
}

.no-links-multimedia__items__img{
    max-width: 67px;
    max-height: 45px;
    margin-right: 19px;
}

.no-links-multimedia__item__select{
    margin-right: 29px;
}
.mb-1{
    margin-bottom:1em;
}
.uppy-Root{
    heifht:150px;
}
.col-md-6{
    width:50%;
}
.d-flex{
    display:flex;
}
.d-inline{
    display:inline-flex;
}
.mx-4{
    margin-left:4em;
    margin-right:4em;
}
.at-inputText{
    max-width:150px;
}
.align-items-center{
    align-items:center;
}
.uppy-DragDrop-inner{
    padding: 40px 20px!important;
}