
.org-right-main-panel{
    display: grid;
    grid-template-columns: auto auto;
    
}

.org-right-main-panel__button{
    margin-left: 35px;
    
  }

.org-right-main-panel__mol-language-selector{
  

}

.org-right-main-panel__item{
  
}