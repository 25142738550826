
.mol-editore-lement-icon{
    display: grid;
    grid-template-rows: 40px 20px;
    grid-auto-columns: 40px;
    
   
}



.mol-editore-lement-icon__at-softText{

    margin-top:6px ;
    margin-right:auto ;
    margin-left:auto ;
}


