.drag-and-drop-viewer{
    width: 100%;
    height: 100%;
}

.div1{
    width: 150px;
    min-height: 50px;
    background-color: red;
}
.div2{
    width: 150px;
    min-height: 50px;
    background-color: blue;

}


.drag-and-drop-viewer__in-editor{
    
    border:1px dotted black;
}

.drag-and-drop-viewer__in-viewer{
    
}

.incorrect-dnd{
    border:5px solid red !important;
}