



.mol-language-selector{
    
    padding-top: 9px;
    
}

.mol-language-selector__at-image{
    margin-right: 8px; 
    width: 20px;
    height: 20px;
    vertical-align: middle;
    display: inline-block;
}

.mol-language-selector__at-select{
    display: inline-block;
    vertical-align: middle;
}