.login{
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: auto;
    margin-top: 50px;
    background-color: white;
    padding: 37px 38px 37px 38px;
    border-radius: 20px;
    box-shadow: 3px 3px 30px -10px #2E4467;
    &__user-text{
        margin-bottom: 30px;
        height: 30px;
    }
    &__password-text{
        margin-bottom: 30px;
        height: 30px;
    }
    &__btn-login{
        
       
    }
}