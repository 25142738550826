@import '../../Styles/Colors.scss';
@import '../../Styles/TextFont.scss';

.multimedia-controller{
   
    
    height: 100%;
    display: flex;
    justify-content: center;

}


.multimedia-controller__content{
    margin-top:54px;
    
    width: 941px;
    height: 621px;

}


.up-load-multimedia-content{
    background-color: red;
}


.panel-administrate{
    display: flex;
    float: right;
    margin-bottom: 24px;

}

.panel-administrate__select{
   margin-top: 9px;
   margin-right: 20px;
}
.panel-administrate__button{
    // padding: 20px 20px 20px 20px;
 }