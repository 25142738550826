.youtube-player{
    &__iframe{
        position: absolute;
        display: block;
        width: 68%;
        height: 53%;
        margin-right: auto;
        margin-left: auto;
        top: 15.7%;
        left: 16%;
        background-color: black;
        & > div{
            height: 100%; 
        }
    } 
}