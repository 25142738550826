@import '../../../Styles/Colors.scss';
@import '../../../Styles/TextFont.scss';

.at-inputText{
    border-width :1px;
    border-radius: 3px 3px 3px 3px;
    border-color: map-get($theme-colors, B6C6DE);
    font-family:map-get($text-font, OpenSans);
    border-width: 1px;
    
    
}

.at-inputText--text{
    height: 24px;
}

.at-inputText--fillable-field-exercise{
    background: none;
    border: none;
    border-bottom: 2px dotted #000;
}