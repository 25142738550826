.viewerController{
    overflow: hidden;
    height: 100vh;
    
}
.viewer-controller_popup-fullscream{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: visible; /* Este valor se cambió de hidden a visible */
    opacity: 1; /* Este valor se cambió de 0 a 1 */
    z-index: 99999;
}
.viewer-controller_popup-fullscream-body{
    position:absolute;
    border-radius: 30px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%);
    transform-origin: center center;
    width: 600px;
    background-color: white;
    padding: 20px;
    display: flex;
    
    &__btns{
        float: right;
        display: flex;
        align-items: center;

        &__btn-full-scream{
            background: none;
        }
    }
   
}
.viewer-btn-full-scream{
    position: absolute;
    top: 3%;
    left: 100%;
    margin-left: -100px;
    z-index: 9999;
    background-color: rgba($color: #000000, $alpha: 0);
}

.floating-viewer-menu{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 3%;
    margin-left: 10px;
    z-index: 9999;
    background-color: #5dbee5;
    height: auto;
    padding: 2px;
    border-radius: 25px;
    transform: scale(0.8);
}
.floating-viewer-menu__at-button{
    height: 100%;
}
.floating-viewer-menu__at-button:not(:first-of-type){
    margin-top: 10px;
}

