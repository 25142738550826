
@import-normalize; 
@import './Styles/Colors.scss';

@font-face {
    font-family: 'Open Sans';
    src: url('./fonts/OpenSans-Regular.ttf') format('truetype');

}

@font-face {
    font-family: 'Ubuntu';
    src:url('./fonts//Ubuntu-Regular.ttf'),
        url('./fonts//Ubuntu-Bold.ttf'),
        url('./fonts//Ubuntu-BoldItalic.ttf'),
        url('./fonts//Ubuntu-Italic.ttf'),
        url('./fonts//Ubuntu-Light.ttf'),
        url('./fonts//Ubuntu-LightItalic.ttf'),
        url('./fonts//Ubuntu-Medium.ttf'),
        url('./fonts//Ubuntu-MediumItalic.ttf');

    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'Escolar';
    src: url('./fonts/Escolar_N.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
}


body{
    font-family: 'Open Sans', sans-serif;
    background-color: map-get($theme-colors, F8F8F8);
    margin:0px;
    //height: 100vh;
    //overflow: hidden;
}



:focus {
    outline: none;
}
button{
    cursor: pointer;
}

p{
  
    margin:0;
    
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.fondo-ficha{
    background-color:#efefef;
    border-radius:5px;
    padding:1em 2em;
    margin: 1em 0em;
}
.inputs-name input, .inputs-name select{
    border-radius:3px;
    margin-left:0.5em;
    margin-right:0.5em;
}
.ficha-titulo{
    text-transform:uppercase;
    font-weight: 500;
}
.wallpaper__item label{
    padding-left:0.5em;
    padding-right:0.5em;
}