button { 
    border:none; 
} 

.at-audio-player{   
    box-shadow: 3px 3px 30px -10px #2E4467;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    display: flex;
    background-color: white;
    &__controls{
        display:flex;
    }
}
.at-audio-player__button-play{
    width: 50px;
    height: 50px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #000000;
}

.at-audio-player__button-pause{
    width: 50px;
    height: 50px;
    background-color: #10305E;
}

.at-audio-player__button-mute{
    width: 50px;
    height: 55px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #ffffff;
}

.button-mute__img{
    position: relative;    
}
.drag-here__icon{
    width: 30px;
    margin:auto;
    padding:0.5rem 0;
    cursor: pointer;
}
.drag-here__icon > .line {
    background-color: #b4b4b4;
    height: 2px;
    display: block;
}
.drag-here__icon > .line + .line {
    margin-top: 3px;
}