@import '../../../Styles/Colors.scss';
@import '../../../Styles/TextFont.scss';



.org-main-horizontal-panel{
  
  background-color: map-get($theme-colors, FFFFFF);
  padding-left:50px;
  padding-right:31px;
  display: grid;
  grid-template-columns: auto auto 300px;
  height:55px;
 
}

.org-main-horizontal-panel__display-none{
  display:none;
}

.org-main-horizontal-panel__item{
  padding-top:9px ;
  padding-bottom:9px ;
}

.org-main-horizontal-panel__item--title{

  margin-top:10px;

}


