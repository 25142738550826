
.image-test-viewer{

    width: 100%;
    margin:auto;
    position:relative;

    &__popup{
       width: 80%;
    }
    
    @media(min-width:1200px){
        width: 100%;
    }
    &__background{
        display: block;
        width: 100%;
    }
    &__exercise{
        position: absolute;
        top: 0;
        left: 14%;
        width: 73%;
        margin: 5% auto 0;
        max-height: 81%;
        overflow-y: auto;
        background-color:#F1F1F1 ;
        border-radius: 10px;
    }
    &__section{
        background-color: rgb(255,255,255);
        padding: 1rem;
        border-radius: 10px;
        margin: 20px 10px 20px 10px;
        box-shadow: 2px 2px 10px #7f7f86;
    }

    &__section-grid{
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 5px;
    }

    &__answers{
        margin-bottom: 20px;
        margin-bottom: 10px;
        padding-bottom: 10px;
        
    }
    &__question{
        margin:auto;
        display:block;

        
    }
    &__option{
        margin: auto;
        margin-left: 0px;
        width: 100%;
        height: 100%;

        &__mol-item{
            height: 100%;
        }

        &-img{
            object-fit: contain;
            max-width: 100%;
            max-height: 100px;
        }
    }
    
}

