.background-multimedia{
    position: relative;
    margin:auto;
    @media(min-width:1200px){
        //width: 80%;
    }
    &__background{
        width: 100%;
        display: block;
    }
    &__flexo{
        width: 100%;
        display: block;
        position: absolute;
        top:0;
        left:0;
        pointer-events: none;
    }
    &--small > div {
        width: 100% !important;
        height: 100% !important;
        top: 0;
        left: 0;
    }
    &--small > &__background, &--small > &__flexo{
        visibility: hidden;
    }

}