@import '../../Styles/Colors.scss';
.table{
    width: 100%;
    
    
    background-color: white;
    padding:37px 38px 37px 38px;
    border-radius: 20px;
    box-shadow: 3px 3px 30px -10px map-get($theme-colors,2E4467);
}


.table__thead{
    font-size: 12px;
    
}

.table__tbody{
    
    font-size: 13px;
    
}


.table-body__tr{
    
    height: 64px;
    
   
}



.table-body__td{
    border-top: 1px solid map-get($theme-colors, F2F6FD);
    margin-right: auto;
}

.footer-paginator{
    display: flex;
    justify-content: center;
    margin-top: 32px;
    
}

.footer-button{
    background: none;
    border: 0;
    color: map-get($theme-colors, 6F86AA);
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
}

.footer-button__left{
    margin-left: 6px;
    margin-right: 30px;
}


.footer-button__right{
    margin-left: 30px;
    margin-right: 6px;
}