.div-response-it p{
  
    margin:0;
    
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.div-response-it{
    width: 100%;
    height: 100%;
}

.div-response-it__overflow{
    
    
}

.div-img-it{
    width: 100%;
    height: 100%;
}

//centrado respuesta
.div-content-it__center{
    width: 100%;
    height: 100%;
    display:flex;
 	align-items: center;
    
    text-overflow: ellipsis; 
}

.img-it__center{
    display:block;
  	margin:auto;
}

.img-it__center-raya{
    display:block;
    margin:auto;
    margin-top: 10px;
}

//arriba izq respuesta
.div-content-it__leftTop{
    width: 100%;
    height: 100%;
    display:flex;
 	align-items: flex-start;
    
    text-overflow: ellipsis; 
}

.img-it__leftTop{
     
  	display:block;
}

.img-it__leftTop-raya{
    display:block;
    margin:auto;
}

//abajo izq respuesta

.div-content-it__leftBottom{
    width: 100%;
    height: 100%;
    display:flex;
 	align-items: flex-end;
    
    text-overflow: ellipsis; 
}

.img-it__leftBottom{
    display:block;
}

.class-name-incorrect-answer-IT{
    
    border: 5px solid red;
}
