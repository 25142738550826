

.mol-rack{
    padding:17px 33px 24px 33px;
}

.mol-rack__header{
    margin:0px 0px 24px 0px;
}

.mol-rack__header__items{
    display: inline-block;
}

.mol-rack__header__select{
    margin:auto 490px auto 0;
}

.mol-rack__header__input-text{}

.mol-rack__header__button{

    margin-left:24px;
}


.mol-rack__body{

    display: grid;
    grid-template-columns: 126px 126px 126px 126px 126px 126px;
    grid-gap:16px 24px;
    margin:0px 0px 17px 0px;

}

.mol-rack__footer{
    display:grid;
    grid-template-columns: 160px auto 160px;
}

.mol-rack__footer__item{
    display:inline-block;
}

.mol-rack__footer__item button:first-child{
    margin-right: 27px;
    
}

.mol-rack__footer__item--pages{

    margin:auto;
}