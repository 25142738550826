$theme-colors: (
  F2F6FD:#F2F6FD,
  B6C6DE:#B6C6DE,
  6F86AA:#6F86AA,
  2E4467:#2E4467,
  091832:#091832,
  FFE4B9:#FFE4B9,
  FFC365:#FFC365,
  F29400:#F29400,
  A26300:#A26300,
  5A3700:#5A3700,
  F5CFDE:#F5CFDE,
  EF85AD:#EF85AD,
  D40050:#D40050,
  960039:#960039,
  650026:#650026,
  CCF2F8:#CCF2F8,
  7BD9E8:#7BD9E8,
  00B3D0:#00B3D0,
  008AA0:#008AA0,
  00525F:#00525F,
  FFFFFF:#FFFFFF,
  F8F8F8:#F8F8F8,
  PANEL_BACKGROUND:#EADCC8,
  BUTTON_1:#ABBD00,
  BUTTON_2:#D60B52,
  PAINT_PALET_1:black,
  PAINT_PALET_2:white,
  PAINT_PALET_3:#FCEE21,
  PAINT_PALET_4:#ED1C24,
  PAINT_PALET_5:#ED1E79,
  PAINT_PALET_6:#F7931E,
  PAINT_PALET_7:#009FE3,
  PAINT_PALET_8:#39B54A,
 );
 