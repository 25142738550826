.div-response-ff p{
  
    margin:0;
    
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.div-response-ff{
    width: 100%;
    height: 100%;
    
}

.div-response-ff__overflow{
    
    overflow: hidden;
}


.div-img-ff{
    width: 100%;
    height: 100%;
}

//centrado respuesta
.div-content-ff__center{
    
    display:flex;
 	align-items: center;
    
    text-overflow: ellipsis; 
}

.div-content-ff__center-son{
    margin:auto;
}


.img-ff__center{
    display:block;
  	margin:auto;
}

//arriba izq respuesta
.div-content-ff__leftTop{
    width: 100%;
    height: 100%;
    display:flex;
 	align-items: flex-start;
    
    text-overflow: ellipsis; 
}

.img-ff__leftTop{
     
  	display:block;
}

//abajo izq respuesta

.div-content-ff__leftBottom{
    width: 100%;
    height: 100%;
    display:flex;
 	align-items: flex-end;
    
    text-overflow: ellipsis; 
}

.img-ff__leftBottom{
    display:block;
}
.class-name-incorrect-answer-FF{
    color:red!important;
}



