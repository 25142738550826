@import '../../../../../Styles/Colors.scss';

.elements-list{

    display: flex;
}

.elements-list:hover{

    background-color: map-get($theme-colors, F2F6FD);

}

.elements-list__first-button{
    margin:auto auto auto 0;
}