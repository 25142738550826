.editor-controller__zoom{
    cursor: zoom-in;
}

.TransformComponent-module_container__3NwNd{

    //width: 100%;
   // height: 100%;

}

.prueba{
    //width: 100%;
    //height: 848px;
}

.editor-controller__grid-handler{
    position: fixed;
    margin-top: -38px;
    top:100%;
    left:182px;
}