@import './../../../Styles/Colors.scss';
@import '../../../Styles/GeneralCssClasses.scss';


.panel-modifier-controller{
    position: absolute;
    top:95px;
    right: 0px;
    
}
.panel-modifier-controller-content{

    background-color:map-get($theme-colors, FFFFFF);
    display: grid;
    padding:19px 24px 19px 24px;
    width: 234px;
    border-radius: 20px 20px 20px 20px;
    margin-left: -306px;
    position:absolute;
    box-shadow: 3px 3px 30px -10px map-get($theme-colors,2E4467);
    margin-right: 24px;
    animation-name: upRightOpen;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    
}

.panel-modifier-controller__close{
    
    animation-name: upRight;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.panel-modifier-controller__properties{
    display:grid;
    grid-template-rows: auto;
    font-size:11px;
    color:map-get($theme-colors, 2E4467);
    
}

.panel-modifier-controller__elements > div{
    
    margin:7px 0;

}



.properties__items{
    display: grid;
    column-gap:22px;
    row-gap: 12px;
    grid-template-columns: 117px 94px;
    
}

.properties__multimedia-Animation{
    display: grid;
    column-gap:22px;
    row-gap: 12px;
    grid-template-columns: auto;
    
}

.properties__colum__item > .mol-tex-and-input-text{
    margin-bottom: 12px;
}

.properties__colum__item > .at-title{
    display:block;
    margin-bottom: 8px;
}


.properties__colum{
    display: grid;
    column-gap:22px;
    row-gap: 12px;
    grid-template-columns: 117px 94px;
}

.panel-modifier-controller__at-button{
    background-color:map-get($theme-colors, FFFFFF);
    width: 20px;
    height: 20px;
    position: absolute;
    top:27px;
    
    
    margin-left: -60px;

    border-radius: 15px 0px 0px 15px;
    
    animation-name: slideButtonOpen;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}


.panel-modifier-controller__at-button > .at-button{
    display: block;
    margin: auto;
    width: 100%;
    height: 100%;
    font-size: 15px;
    
}


.panel-modifier-controller__at-button__close{
    background-color:map-get($theme-colors, FFFFFF);
    width: 50px;
    height: 57px;
    position: absolute;
    
    right: 0px;
    
    margin-left: -50px;

    border-radius: 15px 0px 0px 15px;

    animation-name: slideButton;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}



.border-Select{
    grid-column: 1 / span 2;
}

.text--2colums{
    grid-column: 1 / span 2;
}

@keyframes upRight{
    0% {
        right: 0px;
    }
    
    100% {
       right: -320px;
    }
}

@keyframes upRightOpen{
    0% {
        right: -320px;
    }
    
    100% {
        right: 0px;
    }
}

@keyframes slideButton{
    0% {
        width: 20px;
        height: 20px;
        margin-left: -60px;
    }
    
    100% {
        background-color:map-get($theme-colors, FFFFFF);
        width: 50px;
        height: 57px;
        margin-left: -50px;
        
    }
}

@keyframes slideButtonOpen{
    0% {
        background-color:map-get($theme-colors, FFFFFF);
        width: 50px;
        height: 57px;
        margin-left: -50px;
    }
    
    100% {
        width: 20px;
        height: 20px;
        margin-left: -60px;
    }
}

