@import '../../../Styles/Colors.scss';

.at-canvas{
   // position:absolute;
    
    //left: 50%;
    //top:50%;
    background-size: 100% 100%;
    
   
}

.at-canvas__container{
    
    
}