
.up-load-multimedia{
    margin:17px 32px 28px 32px;
    //width: 788px;
    //height: 566px;
    //background-color: red;
}

.up-load-multimedia__header{
    margin-bottom: 31px;
}

.up-load-multimedia__footer{
    display: flex;
}

.up-load-multimedia__footer--first{
    margin-left: auto;
    margin-right: 16px;
}

.up-load-box{
    margin-bottom: 15px;
}


.up-load-multimedia__button-add-new-link{
    margin-left: 50%;
}