@import '../../../Styles/Colors.scss';
@import '../../../Styles/TextFont.scss';

.dt-boton{

    //display: inline-block;
}

.dt-boton__img{
    margin-right: 10px;
   //vertical-align: middle;
}


.dt-boton__button{
    background: none;
    border: 0;
    color: map-get($theme-colors, 6F86AA);
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;

    //vertical-align: middle;
}
