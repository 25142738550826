@import './../../../Styles/Colors.scss';
@import '../../../Styles/TextFont.scss';
.at-div-rack{
    
}

.at-div-rack__label{
    color:map-get($theme-colors, 2E4467);
    font-family:map-get($text-font, OpenSans);
    display:block;
    margin-top:4px;
    text-align: center;
    font-size: 11px;
    
}