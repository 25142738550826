.musical-noteIn-viewer{
    position:absolute;
    top:50px;
    left: 500px;
    z-index: 7999;
}

.musical-note-in-viewer__img{
    pointer-events: none;
    object-fit: contain;
}

