.mol_comments{
    position: absolute;
    top: 5rem;
    left: 5rem;
    width: 20rem;
    background-color: #9c1859;
    border: 0.25rem solid white;
    border-radius: 1rem;
    padding: 0.5rem;
}
.mol_comments_comment{
    width: 100%;
    padding: 0.25rem 1rem;
    background-color: white;
    border-radius: 1rem;
    box-sizing: border-box;
    margin-bottom: 0.5rem;
}
.mol_comments_new-mesage > textarea{
    width: 100%;
    height: 8rem;
    padding: 0.25rem 1rem;
    box-sizing: border-box;
    margin-bottom: 0.5rem;
}