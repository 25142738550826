.links-multimedia{
    margin-top: 31px;
    
}

.links-multimedia__items{
    display:flex;
    margin-bottom: 16px;
}
.links-multimedia__input{
    flex-grow: 1;
}