@import '../../../Styles/Colors.scss';


.tools-panel{
    background-color: map-get($theme-colors, FFFFFF);
    display: grid;
    
    grid-template-columns: 96px;
    

    border-radius: 20px 20px 20px 20px;
    
    

    padding-top: 15px;
    padding-bottom: 15px;

    position: fixed; 
    top:50%;
    margin-top:-195px;
    margin-left: 38px;

    box-shadow: 3px 3px 30px -10px map-get($theme-colors,2E4467);
}

.tools-panel__item{

    margin-right: auto;
    margin-left: auto;
    margin-bottom: 15px;
    margin-top:15px;
   
}

.tools-panel__popup-item{
  margin: 15px 24px 15px 0px;

}

.popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  /* The actual popup (appears on top) */
  .popup .popuptext {
    visibility: hidden;
    
    background-color:map-get($theme-colors, FFFFFF);
    //background-color: red;
    color: #fff;
    
    border-radius: 15px;
    padding-left: 24px;
    position: absolute;
    z-index: 1;
    bottom: 0%;
    margin-left: 81px;
    
    display:flex;
    
  }
  
  /* Popup arrow */
  .popup .popuptext::after {
    content: "";
    position: absolute;
    top: 30%;
    left:-15%;
    z-index: -1;
    border-width: 15px;
    border-style: solid;
    border-color:  transparent map-get($theme-colors, FFFFFF) transparent transparent;
  }
  
  /* Toggle this class when clicking on the popup container (hide and show the popup) */
  .popup .show {
    visibility: visible;
    
  }
  
 