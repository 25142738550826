
.mol-multimedia-selected{
    

}

.mol-multimedia-selected__at-image{

    margin-right: 20px;
    vertical-align: middle;
    
}

.mol-multimedia-selected__label{
    vertical-align: middle;
}