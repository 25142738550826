.div-response-ts p{
  
    margin:0;
    
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.div-response-ts{
    width: 100%;
    height: 100%;
}

.div-response-ts__overflow{
    
    overflow: hidden;
}

.div-img-ts{
    width: 100%;
    height: 100%;
}

//centrado respuesta
.div-content-ts__center{
    width: 100%;
    height: 100%;
    display:flex;
 	align-items: center;
    
    text-overflow: ellipsis; 
}

.div-content-ts__center p{
    margin:auto;
}

.img-ts__center{
    display:block;
  	margin:auto;
}

.img-ts__center-raya{
    display:block;
    margin:auto;
    margin-top: 10px;
}

//arriba izq respuesta
.div-content-ts__leftTop{
    width: 100%;
    height: 100%;
    display:flex;
 	align-items: flex-start;
    
    text-overflow: ellipsis; 
}

.img-ts__leftTop{
     
  	display:block;
}

.img-ts__leftTop-raya{
    display:block;
    margin:auto;
}

//abajo izq respuesta

.div-content-ts__leftBottom{
    width: 100%;
    height: 100%;
    display:flex;
 	align-items: flex-end;
    
    text-overflow: ellipsis; 
}

.img-ts__leftBottom{
    display:block;
}

.class-name-incorrect-answer-TS{
    color:red!important;
}


