@import '../../../Styles/Colors.scss';
@import '../../../Styles/GeneralCssClasses.scss';

.at-search-selector__values-container{
    height: 100px;
    //overflow:scroll;
    overflow-x: hidden;
    overflow-y: scroll;

    position: fixed; 
    background-color: map-get($theme-colors, FFFFFF);
}

.at-search-selector__container-item > button{
    background: none;
    border: 0;
    color: inherit;
    /* cursor: default; */
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
}
