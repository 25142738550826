.links-multimedia{
    width: 788px;
    margin-top: 31px;
    
}

.links-multimedia__items{
    display:inline-block;
    margin-right: 22px;
    margin-bottom: 16px;
}