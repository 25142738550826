@import '../../../Styles/Colors.scss';
@import '../../../Styles/TextFont.scss';

.at-button{
    border:none;
    background-color: map-get($theme-colors, 00B3D0);
    font-family:map-get($text-font, OpenSans);
    color: map-get($theme-colors, FFFFFF);
    font-size: 14px;
    font-weight: bold;
    height: 37px;
    border-radius: 20px 20px 20px 20px;
    padding: 9px 24px 9px 24px;
    display: inline-block;
}

.at-button--secondary{
   
    background-color: map-get($theme-colors, F2F6FD);
    color: map-get($theme-colors, 6F86AA);

}

.at-button--tertiary{
    
    background: none;
    color: map-get($theme-colors, 6F86AA);
    font-size: 12px;
    padding:0;
    
}

.at-button__img{
    margin-right: 8px; 
    vertical-align: middle;
    
    
}
.at-button__img--alone{
    margin-right: 0px; 
   
    
}

.at-button__img--right{
    margin:0px 0px 0px 8px;
}

.at-button__span{
    
    //vertical-align: middle;
}